import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import axios from 'axios';

import {
  ISettingsDTO,
  IUpdateUserDTO,
  IUserDTO,
  IListUserDTO,
  IUserPermissionsDTO,
} from '../typescript/interfaces';
import { Method } from '../typescript/types';

const API_URL = process.env.REACT_APP_API_URL ?? '';

const getAxiosConfig = <T>(
  method: Method,
  token: string | GetTokenSilentlyVerboseResponse | undefined,
  path: string,
  data?: T,
) => ({
  method,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  url: `${API_URL}/${path}`,
  data,
});

interface IDTO {
  token: string | GetTokenSilentlyVerboseResponse | undefined;
}

export const getUsers = async (token: string, companyId: string): Promise<IUserDTO[]> => {
  const response = await axios(getAxiosConfig('GET', token, `company/${companyId}/user`));
  return response.data;
};

export const getAllUsers = async (token: string): Promise<IListUserDTO[]> => {
  const response = await axios(getAxiosConfig('GET', token, `user`));
  return response.data;
};

export const getUser = async (token: string, id: string, companyId: string): Promise<IUserDTO> => {
  const response = await axios(getAxiosConfig('GET', token, `company/${companyId}/user/${id}`));
  return response.data;
};

export const getUserByEmail = async (
  token: string,
  email: string,
  companyId: string,
): Promise<IUserDTO> => {
  const response = await axios(
    getAxiosConfig('GET', token, `company/${companyId}/user/by/${email}`),
  );
  return response.data;
};

export interface IDeleteUser extends IDTO {
  userId: string;
  companyId: string;
}

export const deleteUser = async ({ token, userId, companyId }: IDeleteUser): Promise<IUserDTO> => {
  const response = await axios(
    getAxiosConfig('DELETE', token, `company/${companyId}/user/${userId}`),
  );
  return response.data;
};

export const getUserPermissions = async (token: string): Promise<IUserPermissionsDTO> => {
  const response = await axios(getAxiosConfig('GET', token, `permissions`));
  return response.data;
};

export const getMe = async (token: string): Promise<IUserDTO> => {
  const response = await axios(getAxiosConfig('GET', token, `me`));
  return response.data;
};

interface IAddUser extends IDTO {
  user: IUpdateUserDTO;
}

export const addUser = async ({ token, user }: IAddUser) => {
  const response = await axios(
    getAxiosConfig('POST', token, `company/${user.companyId}/user`, user),
  );
  return response.data;
};

export interface IUpdateUser extends IDTO {
  user: IUpdateUserDTO;
}

export const updateUser = async ({ token, user }: IUpdateUser): Promise<IUserDTO> => {
  const response = await axios(
    getAxiosConfig('PUT', token, `company/${user.companyId}/user/${user.id}`, user),
  );
  return response.data;
};

export const getSettings = async (token: string): Promise<ISettingsDTO> => {
  const response = await axios(getAxiosConfig('GET', token, `user/settings`));
  return response.data;
};

interface IUpdateSettings extends IDTO {
  settings: ISettingsDTO;
}

export const updateSettings = async ({
  token,
  settings,
}: IUpdateSettings): Promise<ISettingsDTO> => {
  const response = await axios(getAxiosConfig('PUT', token, `user/settings`, settings));
  return response.data;
};

export const sendWelcomeEmail = async (token: string, userId: string, companyId?: string) => {
  if (companyId) {
    await axios(getAxiosConfig('POST', token, `company/${companyId}/SendWelcome/${userId}`, {}));
  } else {
    await axios(getAxiosConfig('POST', token, `admin/SendWelcome/${userId}`, {}));
  }
};

export const sendWeeklyReminderEmail = async (
  token: string,
  userId: string,
  companyId?: string,
) => {
  if (companyId) {
    await axios(getAxiosConfig('POST', token, `company/${companyId}/SendReminder/${userId}`, {}));
  } else {
    await axios(getAxiosConfig('POST', token, `admin/SendReminder/${userId}`, {}));
  }
};
