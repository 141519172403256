import { useQuery } from 'react-query';

import { getAllAuditHistory } from '../../../api';
import { useTokenQuery } from '../../../hooks';
import { AuditHistoryTabs } from '../../AuditHistory/AuditHistoryTabs';

export const ViewAllHistory = () => {
  const { data: token } = useTokenQuery();
  const { data: auditHistory, isLoading: auditHistoryLoading } = useQuery(
    ['all-history'],
    () => getAllAuditHistory(token as string, 2000),
    {
      enabled: !!token,
    },
  );

  return <AuditHistoryTabs auditHistory={auditHistory} auditHistoryLoading={auditHistoryLoading} />;
};
