import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Doclink } from 'typescript/types';

import { useTokenQuery } from '.';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

interface AttachmentCheckResponse {
  expires: number;
}

const request = async (doclink: Doclink, companyId: string, token: string) => {
  const { doclinksid } = doclink;
  const data: AxiosResponse<AttachmentCheckResponse> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${REACT_APP_API_URL}/company/${companyId}/workorder/attachment/check/${doclinksid}`,
  });
  return data;
};

export const useGetWorkOrderAttachmentsCheck = (docLinks: Doclink[], companyId: string) => {
  const { data: token } = useTokenQuery();
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<Record<string, AttachmentCheckResponse>>();

  useEffect(() => {
    const setup = async () => {
      const promises = docLinks.map((doclink) =>
        request(doclink, companyId, token as string).then((x) => ({
          id: doclink.doclinksid,
          response: x.data,
        })),
      );

      try {
        const results = await Promise.all(promises);
        setData(results.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.response }), {}));
      } catch (error) {
        setData(undefined);
        setError(error as Error);
      }
    };

    setup();
  }, [docLinks, companyId, token]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const intervalHandle = setInterval(() => {
      const newData = Object.entries(data);

      newData.forEach(([id, x]) => {
        if (x.expires > 0) {
          x.expires -= 10;
        }
      });

      setData(Object.fromEntries(newData));
    }, 10000);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [data]);

  return {
    error,
    data,
  };
};
