import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IHistoryDTO } from 'typescript/interfaces';

import { Loader } from '../../components/Loader/Loader';
import { TableWithFilter } from '../../components/UI';
import { useAppState } from '../../hooks';
import { getServiceHistoryTableData } from '../../pages/HistoryPage/getServiceHistoryTableData';
import { getWorkorderHistoryTableData } from '../../pages/HistoryPage/getWorkorderHistoryTableData';
import { SmallTabs } from '../SmallTabs/SmallTabs';

interface AuditHistoryTabsProps {
  auditHistory?: IHistoryDTO[];
  auditHistoryLoading: boolean;
}

export const AuditHistoryTabs = ({ auditHistory, auditHistoryLoading }: AuditHistoryTabsProps) => {
  const { t } = useTranslation();

  const workOrderHistory = useMemo(
    () => auditHistory?.filter((x) => x.source.startsWith('Workorder')),
    [auditHistory],
  );

  const systemHistory = useMemo(
    () => auditHistory?.filter((x) => x.source === 'System'),
    [auditHistory],
  );

  return (
    <OuterContainer>
      {auditHistoryLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}

      <SmallTabs
        tabs={[
          {
            label: <p>{t('history.workorder.tabTitle')}</p>,
            content: <WorkorderHistoryTab rows={workOrderHistory ?? []} />,
          },
          {
            label: <p>{t('history.system.tabTitle')}</p>,
            content: <SystemHistoryTab rows={systemHistory ?? []} />,
          },
        ]}
      />
    </OuterContainer>
  );
};

const WorkorderHistoryTab = ({ rows }: { rows: IHistoryDTO[] }) => {
  const { t } = useTranslation();
  const { appState, setAppState } = useAppState();

  const handleRowClick = (id: string) => {
    const {
      WORKORDER_HISTORY: { details },
    } = appState;
    const value = details.get(`${id}_details`);
    details.set(`${id}_details`, value === undefined ? true : !value); // If value is undefined, set it to true, otherwise toggle it
    setAppState({
      ...appState,
      WORKORDER_HISTORY: {
        details: new Map(details),
      },
    });
  };

  return (
    <TableWithFilter
      data={rows}
      getTableData={getWorkorderHistoryTableData}
      filterPlaceholder={`${t('history.workorder.filterPlaceholder')}`}
      subPage="WORKORDER"
      hideLabelInCell={false}
      handleRowClick={handleRowClick}
      hasExpandableRows={true}
      defaultOrder="asc"
    />
  );
};

const SystemHistoryTab = ({ rows }: { rows: IHistoryDTO[] }) => {
  const { t } = useTranslation();

  return (
    <TableWithFilter
      data={rows}
      getTableData={getServiceHistoryTableData}
      filterPlaceholder={`${t('history.system.filterPlaceholder')}`}
      subPage="SYSTEM"
      enableSorting={true}
      defaultOrder="asc"
    />
  );
};

const OuterContainer = styled.div`
  position: relative;
  min-height: 500px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
