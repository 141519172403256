import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import { IUpdateCompletedWorkOrder } from '../typescript/interfaces';
import { WorkOrder } from '../typescript/types';
import { Pluspnextbillstat, PLUSPNEXTBILLSTAT } from '../typescript/types/Pluspnextbillstat';
import { getIsChangesAllowed, washJSON } from '../utils';

const API_APP_API_URL = process.env.REACT_APP_API_URL ?? '';

//****************************/
// Workorders
//****************************/
export const getCompletedWorkOrders = async (token: string, companyId?: string) => {
  if (!companyId) {
    return {
      data: [],
      statusText: 'No company id provided',
    } as unknown as AxiosResponse<WorkOrder[]>;
  }

  const response: AxiosResponse<WorkOrder[]> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformResponse: [washJSON],
    url: `${API_APP_API_URL}/company/${companyId}/workorder/completedwork`,
  });

  return response;
};

//****************************/
// Workorder
//****************************/
export const getCompletedWorkOrder = async (
  id: number,
  org: string,
  companyId: string,
  token: string,
) => {
  const response: AxiosResponse<WorkOrder> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformResponse: [washJSON],
    url: `${API_APP_API_URL}/company/${companyId}/workorder/completedwork/${id}/${org}`,
  });

  return response.data;
};

//****************************/
// Attachments
//****************************/
export const getWorkOrderAttachments = async (
  id: number,
  companyId: string,
  doclinksId: number,
  token: string,
) => {
  const data: AxiosResponse<Blob> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
    url: `${API_APP_API_URL}/company/${companyId}/${id}/doclinksid/${doclinksId}`,
  });
  return data.data;
};

//****************************/
// Update workorder
//****************************/
export const updateCompletedWorkOrder = async ({
  companyId,
  id,
  org,
  fields,
  token,
  user,
}: IUpdateCompletedWorkOrder) => {
  const { isChangesAllowed, message } = getIsChangesAllowed(id);
  if (!isChangesAllowed) {
    return Promise.reject(new Error(message)).then(() => {
      console.warn(message);
    });
  }

  let { pluspcustponum, pluspcostcenter, pluspnextbillstat, pluspnextbillmemo, comment } = fields;

  const worklog = [
    {
      description: 'PA-COMP - Sent from PreApproval',
      description_longdescription: `Response: ${pluspnextbillstat}\n\n Customer PO: ${pluspcustponum}\n Customer Reference:  ${pluspcostcenter}\n\n Customer Comment:\n ${
        comment ?? ''
      }`,
      logtype: 'NOTE',
      clientviewable: '1',
    },
  ];

  const getPluspnextbillmemoText = (pluspnextbillstat: Pluspnextbillstat) => {
    switch (pluspnextbillstat) {
      case PLUSPNEXTBILLSTAT.DISPUTE:
        return 'Discuss before invoice';
      case PLUSPNEXTBILLSTAT.REJECT:
        return 'Rejected via PreApproval Completed Work';
      case PLUSPNEXTBILLSTAT.APPROVE:
        return 'Approved via PreApproval Completed Work';
      default:
        return '';
    }
  };

  pluspnextbillmemo = getPluspnextbillmemoText(pluspnextbillstat);

  await axios({
    method: 'PATCH',
    url: `${API_APP_API_URL}/company/${companyId}/workorder/completedwork/${id}/${org}`,
    data: {
      pluspcustponum,
      pluspcostcenter,
      pluspnextbillstat,
      pluspnextbillmemo,
      worklog,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
