import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import { IUpdateProposedWorkOrder } from '../typescript/interfaces';
import { WorkOrder, Pluspestat, PLUSPESTAT } from '../typescript/types';
import { getIsChangesAllowed, washJSON } from '../utils';

const API_APP_API_URL = process.env.REACT_APP_API_URL ?? '';

//****************************/
// Workorders
//****************************/

export const getProposedWorkOrders = async (token: string, companyId?: string) => {
  // If companyId is not provided return an AxiosResponse with an empty array
  if (!companyId) {
    return {
      data: [],
      statusText: 'No company id provided',
    } as unknown as AxiosResponse<WorkOrder[]>;
  }
  const response: AxiosResponse<WorkOrder[]> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformResponse: [washJSON],
    url: `${API_APP_API_URL}/company/${companyId}/workorder/proposedwork`,
  });

  return response;
};

//****************************/
// Workorder
//****************************/

export const getProposedWorkOrder = async (
  id: number,
  org: string,
  companyId: string,
  token: string,
) => {
  const response: AxiosResponse<WorkOrder> = await axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformResponse: [washJSON],
    url: `${API_APP_API_URL}/company/${companyId}/workorder/proposedwork/${id}/${org}`,
  });

  return response.data;
};

//****************************/
// Update workorder
//****************************/

export const updateProposedWorkOrder = async ({
  companyId,
  id,
  org,
  fields,
  token,
  user,
}: IUpdateProposedWorkOrder) => {
  const { isChangesAllowed, message } = getIsChangesAllowed(id);
  if (!isChangesAllowed) {
    return Promise.reject(new Error(message)).then(() => {
      console.warn(message);
    });
  }

  let { pluspcustponum, pluspcostcenter, pluspeststat, pluspnextbillmemo, comment } = fields;

  const worklog = [
    {
      description: 'PA-WAPPR - Sent from PreApproval',
      description_longdescription: `Response: ${pluspeststat}\n\n Customer PO: ${pluspcustponum}\n Customer Reference:  ${pluspcostcenter}\n\n Customer Comment:\n ${
        comment ?? ''
      }`,
      logtype: 'NOTE',
      clientviewable: '1',
    },
  ];

  if (pluspeststat === PLUSPESTAT.REJECTED) {
    pluspnextbillmemo = 'Rejected via PreApproval Proposed Work';
  }

  const getPluspnextbillmemoText = (pluspnextbillstat: Pluspestat) => {
    switch (pluspnextbillstat) {
      case PLUSPESTAT.REJECTED:
        return 'Rejected via PreApproval Proposed Work';
      case PLUSPESTAT.ACCEPTED:
        return 'Approved via PreApproval Proposed Work';
      default:
        return '';
    }
  };

  pluspnextbillmemo = getPluspnextbillmemoText(pluspeststat);

  await axios({
    method: 'PATCH',
    url: `${API_APP_API_URL}/company/${companyId}/workorder/proposedwork/${id}/${org}`,
    data: {
      pluspcustponum,
      pluspcostcenter,
      pluspeststat,
      pluspnextbillmemo,
      worklog,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
