import { useQuery } from 'react-query';

import { getUserAuditHistory } from '../../../../api';
import { AuditHistoryTabs } from '../../../../components/AuditHistory/AuditHistoryTabs';
import { useTokenQuery } from '../../../../hooks';
import { IUserDTO } from '../../../../typescript/interfaces';

interface IProps {
  user: IUserDTO;
  companyId: string;
}

export const UserHistory = ({ user, companyId }: IProps) => {
  const { data: token } = useTokenQuery();
  const { data: auditHistory, isLoading: auditHistoryLoading } = useQuery(
    ['user-audit-history', user.id, companyId],
    () => getUserAuditHistory(token as string, user.id, companyId),
    {
      enabled: !!token,
    },
  );

  return <AuditHistoryTabs auditHistory={auditHistory} auditHistoryLoading={auditHistoryLoading} />;
};
