import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { useTokenQuery } from '.';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export interface IWorkOrderAttachment {
  workorderid: string;
  doclinksid: string;
  orgid: string;
  urlname: string;
  companyId: string;
}

const request = async (workOrderAttachment: IWorkOrderAttachment, token: string) => {
  if (Object.keys(workOrderAttachment).length === 0) {
    return null;
  }

  const { doclinksid, companyId } = workOrderAttachment;
  const data: AxiosResponse<Blob> = await axios({
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${REACT_APP_API_URL}/company/${companyId}/workorder/attachment/${doclinksid}`,
  });
  return data;
};

export const useGetWorkOrderAttachments = (workOrderAttachment: IWorkOrderAttachment) => {
  const { data: token } = useTokenQuery();

  return useQuery<AxiosResponse<Blob> | null, Error>(
    ['workorder-attachment', workOrderAttachment],
    () => request(workOrderAttachment, token as string),
    {
      enabled: Object.keys(workOrderAttachment).length > 0,
      refetchOnWindowFocus: false,
    },
  );
};
