import axios from 'axios';

import { Method, WorkOrder } from '../typescript/types';
import { IHistoryDTO } from './../typescript/interfaces/IHistoryDTO';

const API_URL = process.env.REACT_APP_API_URL ?? '';

const getFilterString = (filterArguments: Record<string, unknown>, includeEmpty = false) =>
  Object.entries(filterArguments)
    .filter(([_, value]) => value || includeEmpty)
    .reduce((acc, [key, value]) => [...acc, `${key}=${value}`], [] as string[])
    .join('&');

const getAxiosConfig = <T>(method: Method, token: string, path: string, data?: T) => ({
  method,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  url: `${API_URL}/${path}`,
  data,
});

export interface IWorkOrderFilter {
  id?: string;
  email?: string;
  workorder?: string;
  from?: string;
  to?: string;
}

export const getWorkorderHistory = async (
  token: string,
  companyId: string,
  filter: IWorkOrderFilter | null = null,
  take = 250,
  skip = 0,
): Promise<IHistoryDTO[]> => {
  const filterArguments = {
    ...(filter || {}),
    take,
    skip,
  };

  const response = await axios(
    getAxiosConfig(
      'GET',
      token,
      `company/${companyId}/auditlogs/workorder?${getFilterString(filterArguments)}`,
    ),
  );
  return response.data;
};

export const getAllAuditHistory = async (
  token: string,
  take = 250,
  skip = 0,
): Promise<IHistoryDTO[]> => {
  const filterArguments = {
    listAll: true,
    take,
    skip,
  };

  const response = await axios(
    getAxiosConfig(
      'GET',
      token,
      `company/00000000-0000-0000-0000-000000000000/auditlogs?${getFilterString(filterArguments)}`,
    ),
  );
  return response.data;
};

export const getUserAuditHistory = async (
  token: string,
  userId: string,
  companyId: string,
): Promise<IHistoryDTO[]> => {
  const filterArguments = {
    source: '',
    take: 500,
  };

  const response = await axios(
    getAxiosConfig(
      'GET',
      token,
      `/company/${companyId}/auditlogs/user/${userId}?${getFilterString(filterArguments, true)}`,
    ),
  );
  return response.data;
};

export const getSystemHistory = async (
  token: string,
  companyId: string,
  take = 250,
  skip = 0,
): Promise<IHistoryDTO[]> => {
  const response = await axios(
    getAxiosConfig('GET', token, `company/${companyId}/auditlogs/system?take=${take}&skip=${skip}`),
  );
  return response.data;
};

export const getUpdatedAuditData = async (
  token: string,
  id: string,
  companyId: string,
): Promise<WorkOrder> => {
  if (!id) {
    console.error('No id provided for getUpdatedAuditData');
    return Promise.resolve({} as WorkOrder);
  }
  const response = await axios(
    getAxiosConfig('GET', token, `/company/${companyId}/auditdata/${id}/source`),
  ); //TODO: Change to "update" when endpoint sends back a complete workorder.
  return response.data;
};
