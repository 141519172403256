import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  addUser as addUserAPI,
  deleteUser as deleteUserAPI,
  getMe as getMeAPI,
  getSettings as getSettingsAPI,
  getUser as getUserAPI,
  getUsers as getUsersAPI,
  updateSettings as updateSettingsAPI,
  updateUser as updateUserAPI,
  sendWeeklyReminderEmail as sendWeeklyReminderEmailAPI,
  sendWelcomeEmail as sendWelcomeEmailAPI,
} from '../api';
import { ISettingsDTO, IUpdateUserDTO, IUserDTO } from '../typescript/interfaces';
import { useModal } from '../utils/context';
import { useError } from './useError';
import { useSuccess } from './useSuccess';
import { useTokenQuery } from './useTokenQuery';

export const useUserService = (id?: string, companyId?: string, inhibitApiCalls?: boolean) => {
  const queryClient = useQueryClient();
  const [setError] = useError();
  const [setSuccess] = useSuccess();
  const { closeModal } = useModal();
  const { data: token, isLoading: tokenIsLoading } = useTokenQuery();
  const { isAuthenticated, isLoading: auth0isLoading } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();

  inhibitApiCalls ??= false;

  // Get user
  const {
    data: user,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    refetch: refetchUser,
    error: getUserError,
  } = useQuery<IUserDTO, Error>(
    ['user', id, companyId],
    () => getUserAPI(token as string, id ?? '', companyId ?? ''),
    {
      enabled:
        !!token &&
        isAuthenticated &&
        !auth0isLoading &&
        !tokenIsLoading &&
        !!companyId &&
        !inhibitApiCalls,
    },
  );

  // Get users
  const {
    data: users,
    isLoading: isUsersLoading,
    isFetching: isFetchingLoading,
  } = useQuery(['users', companyId], () => getUsersAPI(token as string, companyId ?? ''), {
    enabled:
      !!token &&
      isAuthenticated &&
      !auth0isLoading &&
      !tokenIsLoading &&
      !!companyId &&
      !inhibitApiCalls,
  });

  // Update user
  const { mutate: mutateUser, isLoading: isUpdateUserLoading } = useMutation(updateUserAPI);

  const updateUser = (user: IUpdateUserDTO) => {
    const data = {
      token,
      user,
    };

    mutateUser(data, {
      onError: (error) => {
        setError(error);
      },
      onSuccess: () => {
        setSuccess(t('admin.user.userUpdated'));
        queryClient.removeQueries(['user', user.id]);
        queryClient.invalidateQueries(['userByEmail', user.email]);
        queryClient.invalidateQueries(['users', companyId]);
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['roles', user.id, companyId]);
        queryClient.invalidateQueries(['permissions']);
        queryClient.invalidateQueries(['proposed-workorders', companyId]);
        queryClient.invalidateQueries(['completed-workorders', companyId]);
        queryClient.invalidateQueries(['company', companyId]);
        closeModal();
      },
    });
  };

  // Add user
  const { mutate: mutateAddUser, isLoading: isAddUserLoading } = useMutation(addUserAPI);
  const addUser = (user: IUpdateUserDTO, successText = t('admin.user.userAdded')) => {
    const data = {
      token,
      user,
    };
    mutateAddUser(data, {
      onSuccess: () => {
        setSuccess(successText);
        queryClient.removeQueries(['user', user.id]);
        queryClient.invalidateQueries(['userByEmail', user.email]);
        queryClient.invalidateQueries(['users', user.companyId]);
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['roles', user.id, user.companyId]);
        queryClient.invalidateQueries(['permissions']);
        queryClient.invalidateQueries(['proposed-workorders', user.companyId]);
        queryClient.invalidateQueries(['completed-workorders', user.companyId]);
        queryClient.invalidateQueries(['company', user.companyId]);
        closeModal();
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  // Delete user from company
  const { mutate: mutateDeleteUser, isLoading: isDeleteUserLoading } = useMutation(deleteUserAPI);
  const deleteUser = (
    userId: string,
    companyId: string,
    successText = t('admin.user.userDeleted'),
  ) => {
    const data = {
      token,
      userId,
      companyId,
    };
    mutateDeleteUser(data, {
      onError: (error) => {
        setError(error);
      },
      onSuccess: () => {
        setSuccess(successText);
        closeModal();
        queryClient.invalidateQueries(['user', userId, companyId]);
        queryClient.invalidateQueries(['users', companyId]);
        queryClient.invalidateQueries(['company', companyId]);
        navigate(`/company/${companyId}/users`);
      },
    });
  };

  // Get current user
  const {
    data: me,
    isLoading: isMeLoading,
    isSuccess: isGetMeSuccess,
    isError: isGetMeError,
    refetch: refetchMe,
  } = useQuery(['me'], () => getMeAPI(token as string), {
    enabled: !!token && isAuthenticated && !auth0isLoading && !tokenIsLoading && !inhibitApiCalls,
  });

  // Get settings
  const { data: settings, isLoading: isSettingsLoading } = useQuery(
    ['settings'],
    () => getSettingsAPI(token as string),
    {
      enabled: !!token && isAuthenticated && !auth0isLoading && !tokenIsLoading && !inhibitApiCalls,
    },
  );

  // Update settings
  const { mutate: mutateSettings, isLoading: isUpdateSettingsLoading } =
    useMutation(updateSettingsAPI);
  const updateSettings = (settings: ISettingsDTO) => {
    const data = {
      token,
      settings,
    };
    mutateSettings(data, {
      onError: (error) => {
        setError(error);
      },
      onSuccess: async () => {
        setSuccess(t('userSettings.settingsIsUpdated'));
        queryClient.invalidateQueries('settings');
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('me');
        refetchUser();
        refetchMe();
        closeModal();
      },
    });
  };

  const sendWelcomeEmail = async (userId: string) => {
    await sendWelcomeEmailAPI(token as string, userId, companyId);
    queryClient.removeQueries(['all-history']);
    queryClient.removeQueries(['user-audit-history']);
    queryClient.removeQueries(['system-history']);
  };

  const sendWeeklyReminderEmail = async (userId: string) => {
    await sendWeeklyReminderEmailAPI(token as string, userId, companyId);
    queryClient.removeQueries(['all-history']);
    queryClient.removeQueries(['user-audit-history']);
    queryClient.removeQueries(['system-history']);
  };

  const isError = isGetMeError;
  const error = getUserError;

  return {
    users,
    user,
    updateUser,
    updateSettings,
    settings,
    me,
    isUsersLoading,
    isUserLoading,
    isUserFetching,
    isUpdateUserLoading,
    isUpdateSettingsLoading,
    isSettingsLoading,
    isMeLoading,
    isGetMeSuccess,
    isFetchingLoading,
    isError,
    isDeleteUserLoading,
    isAddUserLoading,
    error,
    deleteUser,
    addUser,
    sendWelcomeEmail,
    sendWeeklyReminderEmail,
  };
};
