import { cell } from '../components/UI';
import { ITableHeaderCell, IHistoryTableData, ITableCell } from '../typescript/interfaces';
import { History } from '../typescript/types';
import { getFormattedDate } from './getFormattedDate';

interface IRegex {
  [key: string]: RegExp;
}

interface IRow {
  cells: ITableCell[];
  selfAlign?: string;
}

// Regular expressions to match each value that we want to extract from the long description
const FromPreApproval: IRegex = {
  user: /User:\s*([\w\.-]+@[\w\.-]+\.\w+)/,
  timestamp: /Time stamp:\s*([\d-]+\s[\d:]+)/,
  response: /Response:\s*(\w+)/,

  // Needs to include newlines in comments
  // The regex below will break if the format includes new properties after Customer Comment (which it currently doesn't)
  // A fix for this can be achieved by appending ($|(\w| )*:\n) and making the first group non greedy
  // ..however that will remove the ability for users to write "I suggest:\nthat we do"
  customer_comment: /Customer Comment:\s*(.+)/s,
};

const headerCells: ITableHeaderCell[] = [
  { id: 'user', label: 'User' },
  { id: 'timestamp', label: 'Timestamp' },
  { id: 'status', label: 'Status' },
  { id: 'customer_comment', label: 'Customer Comment' },
];

const sortTableRowsByDate = (rows: IRow[]) => {
  const sorted = rows.sort((a, b) => {
    const valA = typeof a.cells[1].value === 'string' ? a.cells[1].value : '';
    const valB = typeof b.cells[1].value === 'string' ? b.cells[1].value : '';
    const dateA = new Date(valA);
    const dateB = new Date(valB);
    return dateA.getTime() - dateB.getTime();
  });

  return sorted;
};

const replaceWhiteSpace = (str: string) => {
  return str.replace(/\s{4}/gis, '\n\n');
};

const getTableRows = (history: IHistoryTableData[]) => {
  const rows: IRow[] = history.map((row: IHistoryTableData) => {
    return {
      cells: [
        cell({ value: row.user }),
        cell({ value: row.timestamp }),
        cell({ value: row.response }),
        cell({ value: replaceWhiteSpace(row.customer_comment) }),
      ],
      selfAlign: row.selfAlign,
    };
  });

  return sortTableRowsByDate(rows);
};

const getPreApprovalFormatedData = (history: History): IHistoryTableData => {
  const parsed = Object.keys(FromPreApproval).reduce((acc, key: string) => {
    const value = FromPreApproval[key].exec(history.description_longdescription)?.[1] ?? '';
    return {
      ...acc,
      [key]: value,
      selfAlign: 'right',
    };
  }, {} as IHistoryTableData);

  // Prefer date from Maximo is set, if not the one in the data will have to do.
  if (history.createdate) {
    parsed.timestamp = getFormattedDate(history.createdate);
  }

  return parsed;
};

const getResponse = (description_longdescription: string) => {
  const match = description_longdescription.match(/Response: ([A-Z]+)/);
  const response = match ? match[1].toLocaleLowerCase() : '';

  return response;
};

const getNonPreApprovalFormatedData = (history: History): IHistoryTableData => {
  const parsed = {
    user: history?.person_displayname ?? '',
    timestamp: getFormattedDate(history.createdate),
    response: getResponse(history.description_longdescription),
    customer_po: '',
    customer_reference: '',
    customer_comment: history.description_longdescription,
    quote_remark: '',
    selfAlign: 'left',
  };

  return parsed;
};

const getParsedData = (histories: History[]) => {
  const parsedLongDescription = histories.map((history: History) => {
    // If ipa and maximo is in the user field, we assume it is from pre-approval
    const isFromPreApproval =
      history?.person_displayname?.toLowerCase().includes('ipa') &&
      history?.person_displayname?.toLowerCase().includes('maximo');
    let parsed = {} as IHistoryTableData;

    if (isFromPreApproval) {
      parsed = getPreApprovalFormatedData(history);
    } else {
      parsed = getNonPreApprovalFormatedData(history);
    }

    return parsed;
  });

  return parsedLongDescription;
};

const getHistoryTableData = (response: string): IHistoryTableData[] => {
  const histories: History[] = JSON.parse(response);
  if (!histories || histories.length === 0) {
    return [];
  }

  return getParsedData(histories);
};

export const getCommunicationLog = (response: string) => {
  const rows = getTableRows(getHistoryTableData(response));
  return {
    headerCells,
    rows,
  };
};
