import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getFormattedDate } from '../../../../utils';
import { Button, Icon, Input, Spinner, LanguageSelect, Switch } from './../../../../components';
import { useUserService } from './../../../../hooks';
import { IUpdateUserDTO, IUserDTO } from './../../../../typescript/interfaces';
import { ResendMessagesBox } from './ResendMessagesBox';

interface IFormData {
  givenName: string;
  givenNameIsValid: boolean | undefined;
  familyName: string;
  familyNameIsValid: boolean | undefined;
  language: string;
  languageIsValid: boolean | undefined;
  isFormChanged: boolean;
  lastSeen: string | undefined;
  notifyUser: boolean;
}

interface IProps {
  user: IUserDTO;
  userRoles: string[] | null;
  companyId: string;
}

export const EditUser = ({ user, userRoles, companyId }: IProps) => {
  const { t } = useTranslation();
  const { updateUser, deleteUser, isDeleteUserLoading, isUpdateSettingsLoading } = useUserService(
    undefined,
    companyId,
  );
  const [isFormValid, setIsFormValid] = useState(false);

  const [formData, setFormData] = useState<IFormData>({
    givenName: user?.givenName ?? '',
    givenNameIsValid: undefined,
    familyName: user?.familyName ?? '',
    familyNameIsValid: undefined,

    lastSeen: user?.lastSeen ?? '',
    language: user?.language ?? '',
    languageIsValid: undefined,
    isFormChanged: false,
    notifyUser: user.notifyUser,
  });

  useEffect(() => {
    const isFormValid =
      formData.givenNameIsValid === true &&
      formData.familyNameIsValid === true &&
      formData.languageIsValid === true &&
      formData.isFormChanged === true &&
      !isUpdateSettingsLoading;
    setIsFormValid(isFormValid);
  }, [formData, isUpdateSettingsLoading]);

  useEffect(() => {
    setFormData({
      givenName: user?.givenName ?? '',
      givenNameIsValid: !!user?.givenName,
      familyName: user?.familyName ?? '',
      familyNameIsValid: !!user?.familyName,
      language: user?.language ?? '',
      languageIsValid: !!user?.language,
      isFormChanged: false,
      lastSeen: user.lastSeen,
      notifyUser: user.notifyUser,
    });
  }, [user]);

  const removeUserFromCompany = (userId: string, companyId: string) => {
    const isConfirmed = window.confirm(`${t('general.confirmAction')}`);

    if (!isConfirmed || userId === '' || companyId === '') {
      return;
    }

    deleteUser(userId, companyId);
  };

  const getIsChangedElementValid = (name: string, value: string) => {
    if (name === 'givenName') {
      return !!value;
    }

    if (name === 'familyName') {
      return !!value;
    }

    if (name === 'language') {
      return !!value;
    }

    return false;
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    if (name === '') {
      return;
    }

    const isValid = getIsChangedElementValid(name, value);

    setFormData({ ...formData, [name]: value, [`${name}IsValid`]: isValid, isFormChanged: true });
  };

  const handleNotifyUserChange = (value: boolean) => {
    setFormData({ ...formData, notifyUser: value, isFormChanged: true });
  };

  const handleLanguageChange = (value: string) => {
    setFormData({ ...formData, language: value, languageIsValid: !!value, isFormChanged: true });
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    formData: IFormData,
    user: IUserDTO,
    userRoles: string[] | null,
    companyId: string,
  ) => {
    e.preventDefault();

    // Convert user to IUpdatedUserDTO
    const updatedUserDTO: IUpdateUserDTO = {
      id: user.id,
      email: user.email,
      givenName: formData.givenName,
      familyName: formData.familyName,
      companyId,
      language: formData.language,
      notify: formData.notifyUser,
      roles: userRoles ?? [],
      assignedAgreements: user.assignedAgreements?.map((agreement) => agreement.id) ?? [],
      assignedLocations: user.assignedLocations?.map((location) => location.id) ?? [],
      assignedServices: user.assignedServices?.map((service) => service.id) ?? [],
    };

    updateUser(updatedUserDTO);
  };

  if (isUpdateSettingsLoading || isDeleteUserLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <Form
      onSubmit={(e) => handleSubmit(e, formData, user, userRoles, companyId)}
      onChange={(e) => handleFormChange(e)}>
      <label htmlFor="givenName">{`${t('admin.user.name')}`}</label>
      <Input
        name="givenName"
        value={user.givenName}
        warning={formData.givenNameIsValid === false}
        placeholder={
          formData.givenNameIsValid === false ? t('admin.user.nameIsRequired') ?? '' : ''
        }
      />

      <label htmlFor="familyName">{`${t('admin.user.surname')}`}</label>
      <Input
        name="familyName"
        value={user.familyName}
        warning={formData.familyNameIsValid === false}
        placeholder={
          formData.familyNameIsValid === false ? t('admin.user.surnameIsRequired') ?? '' : ''
        }
      />

      <label htmlFor="email">{`${t('admin.user.email')}`}</label>
      <Input name="email" defaultValue={user.email} disabled={true} />

      <label htmlFor="lastSeen">{`${t('admin.user.lastSeen')}`}</label>
      <Input name="lastSeen" defaultValue={getFormattedDate(user.lastSeen)} disabled={true} />

      {formData.languageIsValid === false && (
        <Warning>{t('userSettings.languageIsRequired')}</Warning>
      )}
      <label htmlFor="notifyUser">{`${t('userSettings.notifyUser')}`}</label>
      <Switch
        name="notifyUser"
        defaultChecked={formData.notifyUser}
        onCheckedChange={handleNotifyUserChange}
      />
      <LanguageSelect onChange={handleLanguageChange} defaultValue={formData.language} />

      <ResendMessagesBox
        userId={user.id}
        companyId={companyId}
        weeklyReminderAvailable={user.notifyUser}
        weeklyReminderEnabled={formData.notifyUser}
      />

      <ButtonContainer>
        <Button
          type="submit"
          variant="secondary"
          size="small"
          onClick={() => removeUserFromCompany(user.id, companyId)}>
          <Icon icon="delete" />
          {`${t('admin.user.removeUser')}`}
        </Button>
        <Button size="small" type="submit" disabled={!isFormValid}>
          {`${t('admin.user.saveUser')}`}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Warning = styled.p`
  color: ${({ theme }) => theme.colors.text.error};
  font-size: ${({ theme }) => theme.fonts.size.small};
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.medium};
`;
